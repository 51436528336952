const Enum = require('enum');

export default new Enum({
  getReason: {
    id: 'needReasonToShowPassword',
    defaultValue: true,
    order: 0,
    switchLabel: 'Ask a reason before display local administrator passwords',
    tooltipLabel: '',
    isCompanyColumn: true,
    availableOnPremise: true,
  },
  approveAllDevices: {
    id: 'approveAllDevices',
    defaultValue: false,
    order: 1,
    switchLabel: 'Approve devices before register',
    tooltipLabel: '',
    isCompanyColumn: true,
    availableOnPremise: true,
  },
  supportAccess: {
    id: 'supportAccess',
    defaultValue: false,
    order: 2,
    switchLabel: 'Support access',
    tooltipLabel: 'Grant Boardgent support temporary access to your account so we can troubleshoot problems. You can revoke access at any time.',
    isCompanyColumn: false,
    availableOnPremise: false,
  },
});
