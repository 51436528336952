<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Company settings
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <div
      :class="'material-card-content'"
      class="material-card-content center-card"
    >
      <card
        url-help=""
      >
        <div
          slot="body"
          class="columns"
          style="flex:1;flex-direction: row;justify-content: space-between; height: 100%"
        >
          <section class="column">
            <card
              external-card="card material-card"
              internal-card="modal-card-body column"
            >
              <p
                slot="header"
                class="subtitle"
              >
                Information
              </p>
              <div slot="body">
                <b-field
                  :type="errors.has('name') ? 'is-danger':''"
                  label="Name"
                >
                  <b-input
                    v-model="newCompany.name"
                    v-validate="'required'"
                    :disabled="!validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    name="name"
                    type="name"
                  />
                </b-field>
                <span
                  v-show="errors.has('name')"
                  class="help is-danger"
                >{{ errors.first('name') }}</span>
                <b-field
                  :type="errors.has('size') ? 'is-danger':''"
                  label="Size"
                  :message="errors.has('size') ? errors.first('size'):''"
                >
                  <b-select
                    v-model="companySizePlaceholder"
                    v-validate="'required|between:1,10'"
                    :disabled="!validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    type="select"
                    name="size"
                    placeholder="Number of Employees"
                    expanded
                  >
                    <template
                      v-for="({name,value},index) in companySizeValues"
                    >
                      <option
                        v-if="value !== 0"
                        :key="index"
                        :value="value"
                      >
                        {{ name }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
                <b-field
                  :type="errors.has('country') ? 'is-danger':''"
                  label="Country"
                >
                  <b-input
                    v-model="newCompany.country"
                    :disabled="!validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    name="country"
                    type="country"
                  />
                </b-field>
                <span
                  v-show="errors.has('country')"
                  class="help is-danger"
                >{{ errors.first('country') }}</span>
                <b-field
                  :type="errors.has('city') ? 'is-danger':''"
                  label="City"
                >
                  <b-input
                    v-model="newCompany.city"
                    :disabled="!validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    name="city"
                    type="city"
                  />
                </b-field>
                <span
                  v-show="errors.has('city')"
                  class="help is-danger"
                >{{ errors.first('city') }}</span>

                <b-field
                  :type="errors.has('address') ? 'is-danger':''"
                  label="Address"
                >
                  <b-input
                    v-model="newCompany.address"
                    :disabled="!validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    name="address"
                    type="address"
                  />
                </b-field>
                <span
                  v-show="errors.has('address')"
                  class="help is-danger"
                >{{ errors.first('address') }}</span>
                <b-field
                  :type="errors.has('url') ? 'is-danger':''"
                  label="Company URL"
                >
                  <b-input
                    v-model="newCompany.url"
                    v-validate="'url'"
                    :disabled="!validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    name="url"
                    placeholder="https://boardgent.com"
                  />
                </b-field>
                <span
                  v-show="errors.has('url')"
                  class="help is-danger"
                >{{ errors.first('url') }}</span>
                <b-field
                  :type="errors.has('logo') ? 'is-danger':''"
                  label="Company logo"
                >
                  <b-upload
                    v-show="!newCompany.urlLogo"
                    v-model="newCompany.logoFile"
                    accept="image/*"
                    @input="uploadLogoResult"
                  >
                    <span class="file-cta">
                      <b-icon
                        class="file-icon"
                        icon="upload"
                      />
                      <span class="file-label">
                        {{ newCompany.logoUrl ? newCompany.logoUrl : "Click to upload" }}
                      </span>
                    </span>
                  </b-upload>
                  <div
                    v-if="newCompany.urlLogo"
                    style="margin-left: 55px;width: 100px"
                  >
                    <b-tooltip
                      type="is-dark"
                      position="is-right"
                      label="Remove"
                      style="position: relative;left: 110px;margin: -20px;top: 15px;"
                    >
                      <b-button
                        type="is-danger"
                        rounded
                        @click="removeWallpaper"
                      >
                        <b-icon
                          icon="close"
                        />
                      </b-button>
                    </b-tooltip>
                    <img
                      :src="newCompany.urlLogo"
                      width="100"
                    >
                  </div>
                </b-field>
                <span
                  v-show="errors.has('url')"
                  class="help is-danger"
                >{{ errors.first('url') }}</span>
              </div>
            </card>
          </section>
          <section
            class="column"
          >
            <div
              class="card material-card centerCardVertical"
            >
              <card
                class="centerCardVertical"
                external-card="card material-card"
                internal-card="column"
              >
                <div
                  slot="body"
                >
                  <p
                    slot="header"
                    class="subtitle"
                  >
                    General
                  </p>
                  <div
                    v-for="companySetting in companySettings.enums"
                    :key="companySetting.value.id"
                  >
                    <div class="field mb-2">
                      <b-switch
                        v-if="companySettingsValues.find(
                          (settingValue) => settingValue.id === companySetting.value.id
                        )"
                        v-model="companySettingsValues.find(
                          (settingValue) => settingValue.id === companySetting.value.id
                        ).value"
                        :disabled="!validateIfHasPermission([
                          permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                        ])"
                      >
                        {{ companySetting.value.switchLabel }}
                        <b-tooltip
                          v-if="companySetting.value.tooltipLabel"
                          :label="companySetting.value.tooltipLabel"
                          position="is-right"
                          multilined
                          size="is-large"
                          type="is-dark"
                        >
                          <b-icon
                            icon="help-circle"
                            size="is-small"
                          />
                        </b-tooltip>
                      </b-switch>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </section>
        </div>
        <div
          slot="body"
          class="columns"
          style="flex:1;flex-direction: row;justify-content: space-between; height: 100%"
        >
          <section
            v-if="hasCapacity"
            class="column"
          >
            <div
              class="card material-card centerCardVertical"
            >
              <card
                class="centerCardVertical"
                external-card="card material-card"
                internal-card="modal-card-body column"
              >
                <div
                  slot="body"
                >
                  <p
                    slot="header"
                    class="subtitle"
                  >
                    Intel® vPro AMT settings
                  </p>
                  <b-field
                    label="Environment detection domains"
                  />
                  <div
                    v-for="field in activeEnvironmentDomains"
                    :key="field"
                  >
                    <b-field
                      :type="environmentDomainsErrors.includes(field - 1) ? 'is-danger':''"
                      style="margin-bottom: 0px;margin-top: 12px;"
                    >
                      <b-input
                        v-model="environmentDomains[field - 1]"
                        v-validate="'required'"
                        expanded
                        :name="`environmentUrl${field - 1}`"
                        type="text"
                        @input="validateDomain"
                      />
                      <p class="control">
                        <button
                          class="button is-danger"
                          @click="removeEnvironmentUrlField(field - 1)"
                        >
                          <b-icon
                            icon="delete"
                          />
                        </button>
                      </p>
                    </b-field>
                    <span
                      v-show="errors.has(`environmentUrl${field - 1}`)"
                      class="help is-danger"
                    >The domain field can not be empty.</span>
                    <span
                      v-show="environmentDomainsErrors.includes(field - 1)"
                      class="help is-danger"
                      style="margin-bottom: 12px;"
                    >This domain is not valid.</span>
                  </div>
                  <div
                    v-show="activeEnvironmentDomains === 0"
                    style="margin: 12px 0px;text-align:center"
                  >
                    <p>
                      There are not environment detection domains configured
                    </p>
                  </div>
                  <button
                    v-if="validateIfHasPermission([
                      permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
                    ])"
                    v-show="activeEnvironmentDomains < maxActiveEnvironmentDomains"
                    class="button is-primary"
                    style="margin-top: 12px;"
                    @click="addEnvironmentUrlField"
                  >
                    <b-icon
                      icon="plus"
                    />
                    <span>Add domain</span>
                  </button>
                </div>
              </card>
            </div>
          </section>
        </div>
      </card>
      <button
        v-if="validateIfHasPermission([
          permissions.MODIFY_COMPANY_INFORMATION.value.dbValue
        ])"
        class="button is-primary force-right mt-5"
        :class="{'is-loading': isLoading }"
        @click="openConfirmationModal"
      >
        Save
      </button>
    </div>
    <b-loading
      :is-full-page="false"
      :active="isUploadingLogo"
      :can-cancel="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import card from '@/components/cross/Card.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import ErrorMixin from '@/mixins/error';
import ExecutionsMixin from '@/mixins/executions';
import toastMessage from '@/helpers/toastMessage';
import snackBarMessage from '@/helpers/snackBarMessage';
import EnvironmentSetter from '@/helpers/environmentSetter';
import vtulEnums from '../../../cross/index';
import CompanyMixin from '../mixins/company';
import RoleMixin from '../mixins/roles';
import companySettings from '../config/companySettings';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;
const { planCapabilities } = vtulEnums.enum.planCapabilities;
const { permissions } = vtulEnums.enum.roles;
const emitToAgentEnum = vtulEnums.enum.emitEvent.emitToAgent;
const executionEnums = vtulEnums.enum.execution.executeAction;

export default {
  name: 'CompanySettings',
  metaInfo: {
    title: 'Settings',
  },
  components: {
    card,
  },
  mixins: [ErrorMixin, ExecutionsMixin, CompanyMixin, RoleMixin],
  data() {
    return {
      isLoading: false,
      environmentDomains: ['', '', ''],
      environmentDomainsErrors: [],
      validateDomainErrors: false,
      activeEnvironmentDomains: 0,
      companySizePlaceholder: undefined,
      maxActiveEnvironmentDomains: 3,
      newCompany: {},
      modalAuth: undefined,
      companySizeValues: vtulEnums.enum.company.sizeTypes.values.value,
      planCapabilities,
      hasCapacity: false,
      permissions,
      companySettings,
      companySettingsValues: companySettings.enums.reduce((result, x) => {
        if (x.value.availableOnPremise || this.$isBgCloud) {
          result.push({
            id: x.value.id,
            value: x.value.defaultValue,
          });
        }
        return result;
      }, []),
      bgApi: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_API'),
      bgApiPath: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_VERSION_PATH'),
      isUploadingLogo: false,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('devices', { findDevicesInStore: 'find' }),
    ...mapGetters('memberships', { findSupportMembershipInStore: 'find' }),
    ...mapGetters('user', { findSupportAccountInStore: 'find' }),
    getSupportAccountInStore() {
      if (this.currentCompany) {
        const user = this.findSupportAccountInStore({
          query: {
            isSupportAccount: true,
            $limit: 1,
          },
        });
        if (user.total <= 0) {
          return null;
        }
        const userMembership = this.findSupportMembershipInStore({
          query: {
            userId: user.data[0].id,
            companyId: this.currentCompany.id,
            $limit: 1,
          },
        });
        if (userMembership.total <= 0) {
          return null;
        }
        return user.data[0];
      }
      return null;
    },
  },
  watch: {
    getSupportAccountInStore: {
      handler(supportAccount) {
        const index = this.companySettingsValues.findIndex(
          (settingValue) => settingValue.id === this.companySettings.supportAccess.value.id,
        );
        if (index !== -1) {
          this.companySettingsValues[index].value = !!supportAccount;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.newCompany = JSON.parse(JSON.stringify(this.currentCompany));
    this.hasCapacity = this.validateIfHasCapability([planCapabilities.DEVICES_MANAGEMENT.value]);
    this.activeEnvironmentDomains = this.currentCompany.environmentDomains.filter((x) => x).length;
    this.currentCompany.environmentDomains.forEach((domain, index) => {
      this.environmentDomains[index] = domain;
    });
    if (this.currentCompany) {
      this.findDevices({
        query: {
          companyId: this.currentCompany.id,
        },
      });
    }
    this.companySizePlaceholder = this.newCompany.size !== 0 ? this.newCompany.size : undefined;
    this.fetchSupportAccountFromApi();
    this.companySettings.enums.forEach((companySetting) => {
      const index = this.companySettingsValues.findIndex(
        (setting) => setting.id === companySetting.value.id,
      );
      if (index !== -1) {
        this.companySettingsValues[index].value = this.currentCompany[companySetting.value.id];
      }
    });
  },
  destroyed() {
    if (this.modalAuth) {
      this.modalAuth.close();
    }
  },
  methods: {
    ...mapActions('companies', { updateCompany: 'patch' }),
    ...mapActions('companies', ['changeCurrentCompany']),
    ...mapActions('devices', { findDevices: 'find' }),
    ...mapActions('company-management', { sendCompanyInvite: 'create' }),
    ...mapActions('memberships', { findSupportAccountMembership: 'find' }),
    ...mapActions('user', { findUsers: 'find' }),
    ...mapActions('emit-to-agent', { emitToAgent: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    ...mapMutations(['clearCurrentSelections']),
    openConfirmationModal() {
      this.$validator.validateAll().then((result) => {
        this.validateDomainErrors = true;
        this.validateDomain();
        if (result && this.environmentDomainsErrors.length < 1) {
          this.$buefy.modal.open({
            parent: this,
            component: confirmation,
            hasModalCard: true,
            canCancel: this.validateIfHasPermission([
              permissions.MODIFY_COMPANY_INFORMATION.value.dbValue,
            ]),
            props: {
              title: this.validateIfHasPermission([
                permissions.MODIFY_COMPANY_INFORMATION.value.dbValue,
              ]) ? 'Confirmation' : 'Not Authorized',
              message: this.validateIfHasPermission([
                permissions.MODIFY_COMPANY_INFORMATION.value.dbValue,
              ]) ? 'Do you want to save changes?' : 'Not Authorized',
              confirmText: this.validateIfHasPermission([
                permissions.MODIFY_COMPANY_INFORMATION.value.dbValue,
              ]) ? 'Save changes' : 'Ok',
              onConfirm: this.validateIfHasPermission([
                permissions.MODIFY_COMPANY_INFORMATION.value.dbValue,
              ]) ? this.deployConfiguration : () => this.$emit('close'),
            },
          });
        }
      }).catch((error) => { throw new Error(error); });
    },
    addEnvironmentUrlField() {
      if (this.activeEnvironmentDomains < this.maxActiveEnvironmentDomains) {
        this.activeEnvironmentDomains += 1;
      }
    },
    updateEnviromentUrlError(index) {
      if (this.$validator.errors.has(`environmentUrl${index + 1}`)) {
        const field = this.$validator.fields.find({
          name: `environmentUrl${index + 1}`,
          scope: this.$options.scope,
        });
        this.$validator.errors.add({
          id: field.id,
          field: `environmentUrl${index}`,
          msg: 'The domain field can not be empty.',
          scope: this.$options.scope,
        });
        this.$validator.errors.remove(`environmentUrl${index + 1}`);
      }
    },
    removeEnvironmentUrlField(position) {
      if (this.$validator.errors.has(`environmentUrl${position}`)) {
        this.$validator.errors.remove(`environmentUrl${position}`);
      }
      for (let i = position; i < this.maxActiveEnvironmentDomains - 1; i += 1) {
        this.updateEnviromentUrlError(i);
        this.environmentDomains[i] = this.environmentDomains[i + 1];
      }
      this.environmentDomains[this.environmentDomains.length - 1] = '';
      this.activeEnvironmentDomains -= 1;
      this.validateDomain();
    },
    async deployConfiguration() {
      this.isLoading = true;
      const finalDomains = [];
      this.environmentDomains.forEach((url) => {
        finalDomains.push(url.toLowerCase().trim());
      });
      this.newCompany.name = `${this.newCompany.name.charAt(0).toUpperCase()}${this.newCompany.name.slice(1)}`;
      if (this.newCompany.country) {
        this.newCompany.country = `${this.newCompany.country.charAt(0).toUpperCase()}${this.newCompany.country.slice(1)}`;
      }
      if (this.newCompany.city) {
        this.newCompany.city = `${this.newCompany.city.charAt(0).toUpperCase()}${this.newCompany.city.slice(1)}`;
      }
      this.newCompany.environmentDomains = finalDomains;
      this.newCompany.size = this.companySizePlaceholder;

      this.companySettings.enums.forEach((companySetting) => {
        if (companySetting.value.isCompanyColumn) {
          this.newCompany[companySetting.value.id] = this.companySettingsValues.find(
            (settingValue) => settingValue.id === companySetting.value.id,
          ).value;
        }
      });
      try {
        const companyResponse = await this.updateCompany([this.currentCompany.id,
          this.newCompany, {}]);
        this.addOrRemoveSupportAccountToThisCompany();
        this.clearCurrentSelections();
        this.changeCurrentCompany(companyResponse);
        const devicesId = [];
        await Promise.all(this.findDevicesInStore({
          query: {
            companyId: companyResponse.id,
            enabled: true,
          },
        }).data.map(async (device) => {
          devicesId.push(device.id);
        }));
        await this.emitToAgent({
          query: {
            type: emitToAgentEnum.EMIT_EXECUTION.value.id,
            executionId: executionEnums.UPDATE_COMPANY_SETTINGS.value.id,
            devicesId,
          },
        });
        const user = await this.$store.getters['auth/user'];
        const log = {
          user: user.email,
          action: 'Company settings updated',
          companyId: this.currentCompany.id,
        };
        await this.createAdminLog(log);
        this.$router.push(`/${companyResponse.id}/devices`);
      } catch (error) {
        this.throwVtulError(operationalUiErrors.UPDATE_COMPANY_ERROR,
          alertBox.SNACKBAR.value, error);
      }
      this.isLoading = false;
    },
    validateDomain() {
      if (!this.validateDomainErrors) {
        return;
      }
      this.environmentDomainsErrors = [];
      this.environmentDomains.forEach((url, index) => {
        if (url) {
          const newUrl = url.trim();
          if (!/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/.test(newUrl)) {
            this.environmentDomainsErrors.push(index);
          }
        }
      });
    },
    async fetchSupportAccountFromApi() {
      const user = await this.findUsers({
        query: {
          isSupportAccount: true,
          $limit: 1,
        },
      });
      if (user.total <= 0) {
        return;
      }
      this.findMemberships({
        query: {
          userId: user.data[0].id,
          companyId: this.currentCompany.id,
          $limit: 1,
        },
      });
    },
    async addOrRemoveSupportAccountToThisCompany() {
      const supportAccessValue = this.companySettingsValues.find(
        (settingValue) => settingValue.id === this.companySettings.supportAccess.value.id,
      );
      if (!supportAccessValue) {
        return;
      }
      try {
        if (supportAccessValue.value) {
          await this.sendCompanyInvite({
            companyId: this.currentCompany.id,
            action: 'addSupportAccount',
          });
        } else {
          await this.sendCompanyInvite({
            companyId: this.currentCompany.id,
            action: 'removeSupportAccount',
          });
        }
      } catch (error) {
        const index = this.companySettingsValues.findIndex(
          (settingValue) => settingValue.id === this.companySettings.supportAccess.value.id,
        );
        this.companySettingsValues[index].value = !supportAccessValue.value;
        toastMessage.showError(error.message);
      }
    },
    async uploadLogoResult() {
      if (!this.newCompany.logoFile) {
        return;
      }
      this.isUploadingLogo = true;

      const formData = new FormData();
      formData.append('file', this.newCompany.logoFile);
      formData.append('companyId', this.currentCompany.id);

      try {
        const apiUrl = new URL(this.bgApi);
        apiUrl.pathname = this.bgApiPath;

        const result = await this.axios.post(`${apiUrl}uploadlogo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (result.status !== 200) {
          throw new Error(result.data);
        }

        this.newCompany.urlLogo = result.data.url;
      } catch (error) {
        snackBarMessage.showError('Unexpected error uploading the file');
        throw error;
      }
      this.isUploadingLogo = false;
    },
    removeWallpaper() {
      this.newCompany.logoFile = null;
      this.newCompany.urlLogo = '';
    },
  },
};
</script>

<style scoped>
.card-form-container {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  max-width: 800px;
}

.centerCardVertical {
  height: 100%;
}

.center-card {
  margin: 0 auto;
}
</style>
